import { isEmpty, flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from '../../../common/components/runtime-context';
import { getIsRecentPostsLoaded, getIsRelatedPostsLoaded } from '../../../common/store/is-loaded/is-loaded-selectors';
import { getRecentPosts } from '../../../common/selectors/recent-posts-selectors';
import AnimatedLoader from '../../../common/components/animated-loader';
import RecentPosts from '../../../recent-posts/components/recent-posts';
import withFeedMetadataSettings from '../../../common/hoc/with-feed-metadata-settings';
import withAuth from '../../../common/hoc/with-auth';
import withDeviceType from '../../../common/hoc/with-device-type';
import withTranslate from '../../../common/hoc/with-translate';
import styles from './post-page.scss';
import { getRelatedPosts } from '../../../common/selectors/related-posts-selectors';

class RelevantPosts extends Component {
  componentDidUpdate = ({ showRecentPosts: prevShowRecentPosts, showRelatedPosts: prevShowRelatedPosts }) => {
    const { post, fetchRecentPosts, fetchRelatedPosts, showRecentPosts, showRelatedPosts } = this.props;

    if (showRecentPosts && showRecentPosts !== prevShowRecentPosts) {
      fetchRecentPosts(post);
    }

    if (showRelatedPosts && showRelatedPosts !== prevShowRelatedPosts) {
      fetchRelatedPosts(post);
    }
  };

  render = () => {
    const {
      t,
      isRecentPostsLoaded,
      isRelatedPostsLoaded,
      showRecentPosts,
      showRelatedPosts,
      relatedPosts,
      relatedPostsLabelKey,
      recentPosts,
    } = this.props;

    if (showRelatedPosts || (showRecentPosts && showRelatedPosts === undefined)) {
      const hasRelatedPosts = !isEmpty(relatedPosts);
      return (
        <AnimatedLoader isLoading={hasRelatedPosts ? !isRelatedPostsLoaded : !isRecentPostsLoaded}>
          <div className={styles.recentPosts}>
            <RecentPosts posts={hasRelatedPosts ? relatedPosts : recentPosts} title={t(relatedPostsLabelKey)} />
          </div>
        </AnimatedLoader>
      );
    }

    return null;
  };
}

RelevantPosts.propTypes = {
  t: PropTypes.func,
  post: PropTypes.object,

  showRelatedPosts: PropTypes.bool,
  relatedPostsLabel: PropTypes.string,
  relatedPostsLabelKey: PropTypes.func.isRequired,

  showRecentPosts: PropTypes.bool,
  isRecentPostsLoaded: PropTypes.bool.isRequired,
  isRelatedPostsLoaded: PropTypes.bool.isRequired,
  fetchRecentPosts: PropTypes.func.isRequired,
  recentPosts: PropTypes.array,
};

const mapRuntimeToProps = (state, { post }, actions) => {
  const postId = post._id;
  return {
    fetchRecentPosts: actions.fetchRecentPosts,
    recentPosts: getRecentPosts(state, postId),

    fetchRelatedPosts: actions.fetchRecentPosts,
    relatedPosts: getRelatedPosts(state, postId),

    isRecentPostsLoaded: getIsRecentPostsLoaded(state, postId),
    isRelatedPostsLoaded: getIsRelatedPostsLoaded(state, postId),
  };
};

export default flowRight(
  connect(mapRuntimeToProps),
  withFeedMetadataSettings,
  withDeviceType,
  withAuth,
  withTranslate,
)(RelevantPosts);
