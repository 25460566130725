import _ from 'lodash';

import { getPostMap } from './post-selectors';
import { createSelector } from 'reselect';

const getRecentPostsIds = (state, postId) => state.recentPostsIds[postId];

// prettier-ignore
export const getRecentPosts = createSelector(
  getPostMap, 
  getRecentPostsIds, 
  (posts, recentPostsIds) => _(posts).pick(recentPostsIds).values().value(),
);
