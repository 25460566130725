import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import Page from '../../../common/components/page';
import forDevice from '../../../common/hoc/for-device';
import withExperiment from '../../../common/hoc/with-experiment';
import { EXPERIMENT_STRETCH_POST_PAGE } from '@wix/communities-blog-experiments';
import styles from './post-page-layout.scss';

const PostPageLayoutMobile = ({ children, ...otherProps }) => <Page {...otherProps}>{children}</Page>;

PostPageLayoutMobile.propTypes = {
  children: PropTypes.node,
};

const PostPageLayoutDesktop = ({ children, className, isExperimentStretchPostPageEnabled, ...otherProps }) => (
  <Page
    className={classNames(styles.container, className, {
      'blog-stretch-post-page': isExperimentStretchPostPageEnabled,
    })}
    {...otherProps}
  >
    {children}
  </Page>
);

PostPageLayoutDesktop.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  isExperimentStretchPostPageEnabled: PropTypes.bool,
};

export default forDevice(
  PostPageLayoutMobile,
  withExperiment({ isExperimentStretchPostPageEnabled: EXPERIMENT_STRETCH_POST_PAGE })(PostPageLayoutDesktop),
);
